



























































































import api from "@/api/index"; //ABP API接口
import { Vue, Component } from "vue-property-decorator";

import {ActivityDto, CmsAnchorDto, CmsAnchorDtoPagedResultDto, QuestionType} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import ExportButton from "@/components/ExportButton/index.vue";

@Component({
  name: "AnchorList",
  components: {
    PagedTableView,
    ExportButton
  },
})
export default class AnchorList extends Vue {
  queryForm = {
    name: "",
    mobile:"",
    sex:""
  };

  sexList=[
    {name:'男',value:'男'},
    {name:'女',value:'女'}];
  exportFieldList = [
    "姓名",
    "手机",
    "性别",
    "生日",
    "省份",
    "城市",
    "区县",
    "详细地址",
    "培训次数",
    "简介"
  ];

  // 获取表数据
  fetchData(params: any) {
    return api.lecturer.getAll(params);
  }

  // 新建
  handleCreate() {
    this.$router.push({
      name: "lecturerCreate",
    });
  }

  // 编辑
  handleEdit(index: number, row: any) {
    this.id = row.id!;
    this.$router.push({
      name: "lecturerEdit",
      params: { id: row.id! + "" },
    });
  }

  // 详情
  handleDetail(index: number, row: any) {
    this.id = row.id!;
    this.$router.push({
      name: "lecturerDetail",
      params: { id: row.id! + "" },
    });
  }

  // 删除
  async handleDelete(index: number, row: any) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.lecturer
        .delete({
          id: row.id,
        })
        .then((res) => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
    });
  }

  handelOnSaved() {
    this.fetchData(this.queryForm);
  }
}
